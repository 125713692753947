.root {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
  bottom: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.7);
}
.progressItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 16px;
}
