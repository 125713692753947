.root {
  font-family: 'Montserrat';
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 20px;
  border-radius: 16px;
  max-width: 380px;
  height: 102px;
}
.image {
  position: absolute;
  bottom: 0px;
  right: 0px;
  max-height: 90%;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}
.block {
  background: white;
  border-radius: 6px;
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  margin-right: 20%;
  > * + * {
    margin-left: 10px;
  }
}
.name {
  flex: 1;
  font-size: 14px;
  font-weight: 600;
  color: black;
}
.badge {
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
}
