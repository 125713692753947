.root {
  overflow: hidden;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
}

.point {
  position: relative;
  z-index: 3;
  width: 40px;
  height: 40px;
  margin: -20px 0 0 -20px;
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0.8);
  background: rgba(255, 255, 255, 0.75);
  cursor: grab;
  opacity: 0.5;
  transition: opacity 0.1s ease-out;

  &:hover {
    opacity: 1;
  }
}

.preview {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(#000, 0.75);
}

.pointDragging {
  opacity: 1;
}
