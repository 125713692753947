.list {
  display: flex;
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  :global {
    .lb-container {
      top: 48px !important;
    }
  }
}
.image {
  max-height: 4rem;
  max-width: 4rem;
}
