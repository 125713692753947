.root {
  margin-top: 20px;

  :global .react-datetime-picker__wrapper {
    font: inherit;
    color: currentColor;
    width: 100%;
    z-index: 10;
    margin: 0;
    padding: 0px 14px;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 4px;
  }

  :global .react-datetime-picker__calendar {
    z-index: 10;
  }
}
