.headingSection {
  display: flex;
  flex-direction: row;
  > * + * {
    margin-left: 20px;
  }
  .info {
    display: flex;
    flex-direction: column;
  }

  .subTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    > * + * {
      margin-left: 20px;
    }
  }
}
