.root {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;

  .title {
    flex: 1;
    padding-right: 10px;
  }
}

.gridList {
  margin: 0;
  overflow-y: hidden !important;
}

.pagination {
  flex: 1;
  display: flex;
  justify-content: center;
}

.tileBar {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.4) 70%, rgba(0, 0, 0, 0) 100%);
}

.activeItem {
  border: 3px solid red;
}

.dragActive {
}

.dialogContent {
  padding-bottom: 0px;
}

.dropZone {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
  bottom: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
}

.dropZoneWrapper {
  position: absolute;
  top: 15px;
  bottom: 15px;
  left: 15px;
  right: 15px;
  border: 3px white dashed;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropZoneText {
}

.dialogTitle {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
}

.dialogTitleActions {
  display: flex;
  justify-content: flex-end;
}
