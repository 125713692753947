.root {
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.emoji {
  cursor: pointer;
  margin-right: 15px;
}
