.root {
  > * + * {
    margin-top: 4px;
  }
}

.image {
  width: 40px;
  height: 40px;
}
