.toolbar {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.title {
  flex: 1;
  padding-right: 10px;
}
.form {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
