.root {
  position: relative;
  width: 100%;
  height: 100%;
}
.image {
  position: relative;
  width: 100%;
  height: 100%;
}
.map {
  width: 100%;
  height: 100%;
}
.imageToolbar {
  display: flex;
  align-items: flex-end;
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
}
