.root {
  max-width: 428px;
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat';
}
.header {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 23px 50px 23px;
  border-radius: 0px;
  height: 306px;
  overflow: hidden;
}
.image {
  max-width: 80%;
  max-height: 80%;
  z-index: 2;
}

.name {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  z-index: 2;
  width: 100%;
}
.desc {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  z-index: 2;
  width: 100%;
}
.background {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.page {
  background-color: #f0ecfb;
  border-top-right-radius: 32px;
  border-top-left-radius: 32px;
  margin-top: -32px;
  z-index: 3;
  padding: 24px;
}
.text {
  background: white;
  padding: 16px;
  border-radius: 16px;
  font-weight: 500;
  font-size: 14px;
  > p {
    margin: 0px;
  }
}
