.timePicker {
  display: flex;
  height: 100%;
  width: 100%;
  :global .rc-time-picker-clear {
    display: none;
  }
}
.popup {
  z-index: 9999999 !important;
  padding-top: 9px;
  position: relative;
}
.input {
  height: 100%;
  border-color: rgba(0, 0, 0, 0.23) !important;
  border-radius: 4px;
  border-width: 1px;
  padding: 10.5px 14px !important;
  background: transparent;
  line-height: 1.1876em !important;
  font-size: 1rem !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.label {
  margin: 0px 0px 0px 0px !important;
}
