.label {
  font-size: 0.8rem;
  margin-bottom: 10px;
}

.timeSep {
  padding: 0 10px;
}

.timeContainer {
  display: flex;
  align-items: center;
}

.timeInput {
  max-width: 75px;
}

.limitInput {
  max-width: 50px;
  padding: 5.5px 0;

  input {
    text-align: center;
  }
}
