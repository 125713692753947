.root {
  font-family: 'Montserrat';
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  > * + * {
    margin-left: 16px;
  }
  padding: 12px 0;
  border-radius: 16px;
  max-width: 380px;
}

.image {
  img {
    max-width: 66px;
  }
}

.name {
  flex: 1;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
}

.offset {
  padding-inline: 15px;
}
