.root {
  position: relative;
  padding: 8px;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 64px);
  overflow-y: auto;
}
.gridList {
  margin: 0;
}
.tileBar {
  height: 40px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.4) 70%, rgba(0, 0, 0, 0) 100%);
}

.titleWrap {
  min-width: 0;
  align-items: flex-end !important;
  padding: 0px 0px 0px 8px !important;
}
.title {
  font-size: 0.8rem !important;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: unset !important;
  line-break: anywhere;
  text-overflow: unset !important;
  line-height: unset !important;
}
.check {
  position: absolute;
  top: 8px;
  right: 8px;
  border: 1px solid white;
  border-radius: 100%;
  background-color: white;
}
.settings {
  color: white !important;
}
.image {
  max-height: 100%;
}
