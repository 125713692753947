.root {
  padding: 8px;
  width: 100%;
}
.mainImage {
  max-height: 200px;
}
.previews {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
  grid-gap: 8px;
  margin: 15px 0px 0px 0px;
}
.previewCard {
  cursor: pointer;
}
.preview {
  max-height: 220px;
  display: block;
}
.previewImage {
  display: block;
}
.previewCardActive {
  border: 2px solid red;
}
