.root {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
  bottom: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
}
.top {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: flex-start;
  padding: 16px 24px;
}
.dialogTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.heading {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-right: 16px;
}
.close {
}
.errorsList {
  width: 100%;
}
